import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 360.000000 360.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
<path d="M1510 2383 c-14 -3 -53 -18 -87 -33 -196 -89 -312 -228 -384 -461
-20 -64 -23 -96 -23 -234 -1 -150 1 -165 27 -240 37 -105 80 -175 147 -239
125 -118 242 -182 363 -198 89 -12 259 -2 222 13 -11 4 -34 8 -51 8 -80 2
-252 79 -301 134 -61 69 -142 203 -159 261 -24 86 -16 285 16 376 29 84 77
174 111 211 15 16 23 29 18 29 -44 0 -169 -125 -206 -207 -14 -29 -27 -53 -30
-53 -10 0 5 153 22 215 19 73 64 172 96 211 57 70 152 153 211 182 61 30 62
35 8 25z"/>
<path d="M2190 2296 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M2064 1598 c-58 -12 -97 -55 -97 -108 0 -59 30 -85 118 -106 111 -26
119 -29 134 -56 13 -23 13 -29 -2 -51 -35 -54 -149 -56 -202 -3 -17 17 -27 20
-44 13 -28 -13 -27 -20 9 -56 62 -62 215 -64 273 -3 31 33 42 88 25 122 -19
36 -71 67 -125 74 -111 16 -149 45 -119 91 31 47 140 54 176 10 10 -12 15 -12
36 2 20 12 22 18 12 30 -31 38 -120 57 -194 41z"/>
<path d="M1530 1393 l0 -203 30 0 30 0 0 95 0 96 91 -96 c86 -90 94 -95 133
-95 l41 0 -105 105 -105 105 80 82 c88 89 105 108 105 112 0 2 -16 3 -36 2
-32 -1 -46 -11 -120 -90 l-84 -88 0 89 0 90 -30 -1 -30 -1 0 -202z"/>
<path d="M2390 1393 l0 -203 25 0 25 0 1 123 c2 148 3 175 9 181 3 2 32 -33
64 -79 32 -47 64 -89 71 -95 10 -8 29 13 81 86 l69 97 3 -156 3 -157 29 0 30
0 0 203 0 202 -36 -1 c-33 -1 -40 -7 -97 -89 -34 -49 -66 -90 -72 -91 -8 -3
-75 87 -126 168 -4 6 -23 11 -43 12 l-36 1 0 -202z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
